import { PolymerElement, html } from "@polymer/polymer/polymer-element";

import "../src/hh-login";

class LoginApp extends PolymerElement {
   static get is() {
      return "login-app";
   }

   static get template() {
      return html`
      <hh-login id="login"></hh-login>
      `;
   }

   constructor() {
      super();

      localStorage.removeItem("user");
      localStorage.removeItem("jwt");
      localStorage.removeItem("jwtExpTime");
      // SSO 로그인 시 사용했던 데이터를 삭제한다.
      // Link login
      localStorage.removeItem("link");
      // 우리메디컬 login
      localStorage.removeItem("accessionNumber");
      // Referral login
      localStorage.removeItem("patientId");
      // Ez Caretech EMR 검색 조건
      localStorage.removeItem("queryParams");
   }

   ready() {
      super.ready();

      this.$.login.open();
   }
}

window.customElements.define(LoginApp.is, LoginApp);
